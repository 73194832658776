var render = function render(){
  var _vm$user, _vm$user$permissions;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.user ? _c('buy-gems-modal') : _vm._e(), _c('footer', [_c('div', {
    staticClass: "product"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t('footerProduct')))]), _c('ul', [_c('li', [_c('a', {
    attrs: {
      "href": "https://itunes.apple.com/us/app/habitica/id994882113?ls=1&mt=8",
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t('mobileIOS')) + " ")])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://play.google.com/store/apps/details?id=com.habitrpg.android.habitica",
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t('mobileAndroid')) + " ")])]), _c('li', [_c('router-link', {
    attrs: {
      "to": _vm.user ? '/group-plans' : '/static/group-plans'
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('groupPlans')) + " ")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/static/features"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('companyAbout')) + " ")])], 1)])]), _c('div', {
    staticClass: "company"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t('footerCompany')))]), _c('ul', [_c('li', [_c('router-link', {
    attrs: {
      "to": "/static/contact"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('contactUs')) + " ")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/static/press-kit"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('presskit')) + " ")])], 1), _c('li', [_c('a', {
    attrs: {
      "href": "https://habitica.wordpress.com/",
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t('companyBlog')) + " ")])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://habitica.fandom.com/wiki/Whats_New",
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t('oldNews')) + " ")])])])]), _c('div', {
    staticClass: "community"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t('footerCommunity')))]), _c('ul', [_c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "/static/community-guidelines"
    }
  }, [_vm._v(_vm._s(_vm.$t('communityGuidelines')) + " ")])]), _c('li', [_c('router-link', {
    attrs: {
      "to": "/hall/contributors"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('hall')) + " ")])], 1), _c('li', [_c('a', {
    attrs: {
      "href": "https://habitica.fandom.com/wiki/Contributing_to_Habitica",
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t('companyContribute')) + " ")])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://translate.habitica.com/",
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t('translateHabitica')) + " ")])])])]), _c('div', {
    staticClass: "support"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t('support')))]), _c('ul', [_c('li', [_c('router-link', {
    attrs: {
      "to": "/static/faq"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('FAQ')) + " ")])], 1), _vm.user ? _c('li', [_c('a', {
    attrs: {
      "href": "",
      "target": "_blank"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.openBugReportModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reportBug')) + " ")])]) : _c('li', [_c('a', {
    attrs: {
      "href": "mailto:admin@habitica.com?subject=Habitica Web Bug Report",
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reportBug')) + " ")])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://docs.google.com/forms/d/e/1FAIpQLScPhrwq_7P1C6PTrI3lbvTsvqGyTNnGzp1ugi1Ml0PFee_p5g/viewform?usp=sf_link",
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t('requestFeature')) + " ")])])])]), _c('div', {
    staticClass: "developers"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t('footerDevs')))]), _c('ul', [_c('li', [_c('a', {
    attrs: {
      "href": "/apidoc",
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t('APIv3')) + " ")])]), _c('li', [_c('a', {
    attrs: {
      "href": _vm.getDataDisplayToolUrl,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t('dataDisplayTool')) + " ")])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://habitica.fandom.com/wiki/Guidance_for_Blacksmiths",
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t('guidanceForBlacksmiths')) + " ")])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://habitica.fandom.com/wiki/Extensions,_Add-Ons,_and_Customizations",
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t('communityExtensions')) + " ")])])])]), _c('div', {
    staticClass: "donate"
  }, [_c('div', [_c('h3', [_vm._v(_vm._s(_vm.$t('helpSupportHabitica')))]), _c('p', {
    staticClass: "donate-text"
  }, [_vm._v(" " + _vm._s(_vm.$t('donateText3')) + " ")])])]), _c('div', {
    staticClass: "donate-button"
  }, [_c('button', {
    staticClass: "btn button btn-secondary btn-contribute",
    on: {
      "click": function ($event) {
        return _vm.donate();
      }
    }
  }, [_c('div', {
    staticClass: "text"
  }, [_vm._v(" 💜 " + _vm._s(_vm.$t('companyDonate')) + " ")])])]), _c('div', {
    staticClass: "social"
  }, [_c('div', [_c('h3', [_vm._v(_vm._s(_vm.$t('footerSocial')))]), _c('div', {
    staticClass: "icons"
  }, [_c('a', {
    staticClass: "social-circle",
    attrs: {
      "href": "https://www.instagram.com/habitica/",
      "target": "_blank"
    }
  }, [_c('div', {
    staticClass: "social-icon svg-icon instagram",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.instagram)
    }
  })]), _c('a', {
    staticClass: "social-circle",
    attrs: {
      "href": "https://twitter.com/habitica/",
      "target": "_blank"
    }
  }, [_c('div', {
    staticClass: "social-icon svg-icon twitter",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.twitter)
    }
  })]), _c('a', {
    staticClass: "social-circle",
    attrs: {
      "href": "https://www.facebook.com/Habitica/",
      "target": "_blank"
    }
  }, [_c('div', {
    staticClass: "social-icon facebook svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.facebook)
    }
  })]), _c('a', {
    staticClass: "social-circle",
    attrs: {
      "href": "http://blog.habitrpg.com/",
      "target": "_blank"
    }
  }, [_c('div', {
    staticClass: "social-icon tumblr svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.tumblr)
    }
  })])])])]), _vm._m(0), _c('div', {
    staticClass: "copyright"
  }, [_c('div', [_vm._v(" © " + _vm._s(_vm.currentYear) + " Habitica. All rights reserved. ")])]), _c('div', {
    staticClass: "melior"
  }, [_c('div', {
    staticClass: "logo svg svg-icon color",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.melior)
    }
  })]), _c('div', {
    staticClass: "privacy-terms"
  }, [_c('span', {
    staticClass: "privacy-policy"
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "/static/privacy"
    }
  }, [_vm._v(_vm._s(_vm.$t('privacy')))])]), _c('span', {
    staticClass: "terms"
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "/static/terms"
    }
  }, [_vm._v(_vm._s(_vm.$t('terms')))])])]), _c('div', {
    staticClass: "privacy-policy mobile desktop"
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "/static/privacy"
    }
  }, [_vm._v(_vm._s(_vm.$t('privacy')))])]), _c('div', {
    staticClass: "mobile-terms mobile desktop"
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "/static/terms"
    }
  }, [_vm._v(_vm._s(_vm.$t('terms')))])]), _vm.TIME_TRAVEL_ENABLED && (_vm$user = _vm.user) !== null && _vm$user !== void 0 && (_vm$user$permissions = _vm$user.permissions) !== null && _vm$user$permissions !== void 0 && _vm$user$permissions.fullAccess ? _c('div', {
    key: _vm.lastTimeJump,
    staticClass: "time-travel"
  }, [_c('a', {
    staticClass: "btn btn-secondary mr-1",
    on: {
      "click": function ($event) {
        return _vm.jumpTime(-1);
      }
    }
  }, [_vm._v("-1 Day")]), _c('a', {
    staticClass: "btn btn-secondary mr-1",
    on: {
      "click": function ($event) {
        return _vm.jumpTime(-7);
      }
    }
  }, [_vm._v("-7 Days")]), _c('a', {
    staticClass: "btn btn-secondary mr-1",
    on: {
      "click": function ($event) {
        return _vm.jumpTime(-30);
      }
    }
  }, [_vm._v("-30 Days")]), _c('div', {
    staticClass: "my-2"
  }, [_vm._v(" Time Traveling! It is " + _vm._s(new Date().toLocaleDateString()) + " "), _c('a', {
    staticClass: "btn btn-small",
    on: {
      "click": function ($event) {
        return _vm.resetTime();
      }
    }
  }, [_vm._v(" Reset ")])]), _c('a', {
    staticClass: "btn btn-secondary mr-1",
    on: {
      "click": function ($event) {
        return _vm.jumpTime(1);
      }
    }
  }, [_vm._v("+1 Day")]), _c('a', {
    staticClass: "btn btn-secondary mr-1",
    on: {
      "click": function ($event) {
        return _vm.jumpTime(7);
      }
    }
  }, [_vm._v("+7 Days")]), _c('a', {
    staticClass: "btn btn-secondary mr-1",
    on: {
      "click": function ($event) {
        return _vm.jumpTime(30);
      }
    }
  }, [_vm._v("+30 Days")])]) : _vm._e(), _vm.DEBUG_ENABLED && _vm.isUserLoaded ? _c('div', {
    staticClass: "debug-toggle"
  }, [_c('button', {
    staticClass: "debug btn-primary",
    on: {
      "click": function ($event) {
        _vm.debugMenuShown = !_vm.debugMenuShown;
      }
    }
  }, [_vm._v(" Toggle Debug Menu ")]), _vm.debugMenuShown ? _c('div', {
    staticClass: "btn debug-toggle debug-group"
  }, [_c('div', {
    staticClass: "debug-pop"
  }, [_c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.setHealthLow();
      }
    }
  }, [_vm._v("Reduce Health to 1")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addMissedDay(1);
      }
    }
  }, [_vm._v("+1 Missed Day")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addMissedDay(2);
      }
    }
  }, [_vm._v("+2 Missed Days")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addMissedDay(8);
      }
    }
  }, [_vm._v("+8 Missed Days")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addMissedDay(32);
      }
    }
  }, [_vm._v("+32 Missed Days")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addTenGems();
      }
    }
  }, [_vm._v("+10 Gems")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addHourglass();
      }
    }
  }, [_vm._v("+1 Mystic Hourglass")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addGold();
      }
    }
  }, [_vm._v("+500GP")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.plusTenHealth();
      }
    }
  }, [_vm._v("+ 10HP")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addMana();
      }
    }
  }, [_vm._v("+MP")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addLevelsAndGold();
      }
    }
  }, [_vm._v("+Exp +GP +MP")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addExp();
      }
    }
  }, [_vm._v("+Exp")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.addOneLevel();
      }
    }
  }, [_vm._v("+1 Level")]), _c('a', {
    staticClass: "btn btn-secondary",
    attrs: {
      "tooltip": "+1000 to boss quests. 300 items to collection quests"
    },
    on: {
      "click": function ($event) {
        return _vm.addQuestProgress();
      }
    }
  }, [_vm._v("Quest Progress Up")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.bossRage();
      }
    }
  }, [_vm._v("+ Boss Rage 😡")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.makeAdmin();
      }
    }
  }, [_vm._v("Make Admin")])])]) : _vm._e()]) : _vm._e()])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hr"
  }, [_c('div', [_c('hr')])]);

}]

export { render, staticRenderFns }