var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('creator-intro'), _c('profileModal'), _c('report-flag-modal'), _c('report-member-modal'), _c('send-gift-modal'), _c('select-user-modal'), _c('b-navbar', {
    staticClass: "topbar navbar-inverse static-top",
    attrs: {
      "id": "habitica-menu",
      "toggleable": "lg",
      "type": "dark"
    }
  }, [_c('b-navbar-brand', {
    staticClass: "brand",
    attrs: {
      "aria-label": "Habitica"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('div', {
    staticClass: "logo svg-icon svg color gryphon pl-2 mr-3",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.melior)
    }
  }), _c('div', {
    staticClass: "svg-icon"
  })]), _c('div', {
    staticClass: "svg-icon"
  })], 1), _c('b-navbar-toggle', {
    staticClass: "menu-toggle",
    attrs: {
      "target": "menu_collapse"
    }
  }), _c('div', {
    staticClass: "quick-menu mobile-only form-inline"
  }, [_c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      value: _vm.$t('sync'),
      expression: "$t('sync')",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "item-with-icon",
    attrs: {
      "aria-label": _vm.$t('sync')
    },
    on: {
      "click": _vm.sync
    }
  }, [_c('div', {
    staticClass: "top-menu-icon svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.sync)
    }
  })]), _c('notification-menu', {
    staticClass: "item-with-icon"
  }), _c('user-dropdown', {
    staticClass: "item-with-icon"
  })], 1), _c('b-collapse', {
    staticClass: "collapse navbar-collapse",
    attrs: {
      "id": "menu_collapse"
    },
    model: {
      value: _vm.menuIsOpen,
      callback: function callback($$v) {
        _vm.menuIsOpen = $$v;
      },
      expression: "menuIsOpen"
    }
  }, [_c('b-navbar-nav', {
    staticClass: "menu-list"
  }, [_c('b-nav-item', {
    staticClass: "topbar-item",
    class: {
      'active': _vm.$route.path === '/'
    },
    attrs: {
      "tag": "li",
      "to": {
        name: 'tasks'
      },
      "exact": "exact"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('tasks')) + " ")]), _c('li', {
    staticClass: "topbar-item droppable",
    class: {
      'active': _vm.$route.path.startsWith('/inventory')
    }
  }, [_c('div', {
    staticClass: "chevron rotate",
    on: {
      "click": function click($event) {
        return _vm.dropdownMobile($event);
      }
    }
  }, [_vm._m(0)]), _c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": {
        name: 'items'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('inventory')) + " ")]), _c('div', {
    staticClass: "topbar-dropdown"
  }, [_c('router-link', {
    staticClass: "topbar-dropdown-item dropdown-item",
    attrs: {
      "to": {
        name: 'items'
      },
      "exact": "exact"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('items')) + " ")]), _c('router-link', {
    staticClass: "topbar-dropdown-item dropdown-item",
    attrs: {
      "to": {
        name: 'equipment'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('equipment')) + " ")]), _c('router-link', {
    staticClass: "topbar-dropdown-item dropdown-item",
    attrs: {
      "to": {
        name: 'stable'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('stable')) + " ")])], 1)], 1), _c('li', {
    staticClass: "topbar-item droppable",
    class: {
      'active': _vm.$route.path.startsWith('/shop')
    }
  }, [_c('div', {
    staticClass: "chevron rotate",
    on: {
      "click": function click($event) {
        return _vm.dropdownMobile($event);
      }
    }
  }, [_vm._m(1)]), _c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": {
        name: 'market'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('shops')) + " ")]), _c('div', {
    staticClass: "topbar-dropdown"
  }, [_c('router-link', {
    staticClass: "topbar-dropdown-item dropdown-item",
    attrs: {
      "to": {
        name: 'market'
      },
      "exact": "exact"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('market')) + " ")]), _c('router-link', {
    staticClass: "topbar-dropdown-item dropdown-item",
    attrs: {
      "to": {
        name: 'quests'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('quests')) + " ")]), _c('router-link', {
    staticClass: "topbar-dropdown-item dropdown-item",
    attrs: {
      "to": {
        name: 'customizations'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('customizations')) + " ")]), _c('router-link', {
    staticClass: "topbar-dropdown-item dropdown-item",
    attrs: {
      "to": {
        name: 'seasonal'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('titleSeasonalShop')) + " ")]), _c('router-link', {
    staticClass: "topbar-dropdown-item dropdown-item",
    attrs: {
      "to": {
        name: 'time'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('titleTimeTravelers')) + " ")])], 1)], 1), _vm.user.party._id && _vm.user._id !== _vm.partyLeaderId ? _c('b-nav-item', {
    staticClass: "topbar-item",
    class: {
      'active': _vm.$route.path.startsWith('/party')
    },
    attrs: {
      "tag": "li",
      "to": {
        name: 'party'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('party')) + " ")]) : _vm._e(), _vm.user.party._id && _vm.user._id === _vm.partyLeaderId ? _c('li', {
    staticClass: "topbar-item droppable",
    class: {
      'active': _vm.$route.path.startsWith('/party')
    }
  }, [_c('div', {
    staticClass: "chevron rotate",
    on: {
      "click": function click($event) {
        return _vm.dropdownMobile($event);
      }
    }
  }, [_vm._m(2)]), _c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": {
        name: 'party'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('party')) + " ")]), _c('div', {
    staticClass: "topbar-dropdown"
  }, [_c('router-link', {
    staticClass: "topbar-dropdown-item dropdown-item",
    attrs: {
      "to": {
        name: 'lookingForParty'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('lookingForPartyTitle')) + " ")])], 1)], 1) : _vm._e(), !_vm.user.party._id ? _c('b-nav-item', {
    staticClass: "topbar-item",
    class: {
      'active': _vm.$route.path.startsWith('/party')
    },
    on: {
      "click": function click($event) {
        return _vm.openPartyModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('party')) + " ")]) : _vm._e(), _c('li', {
    staticClass: "topbar-item droppable",
    class: {
      'active': _vm.$route.path.startsWith('/group-plans')
    }
  }, [_vm.groupPlans && _vm.groupPlans.length > 0 ? _c('div', {
    staticClass: "chevron rotate",
    on: {
      "click": function click($event) {
        return _vm.dropdownMobile($event);
      }
    }
  }, [_vm._m(3)]) : _vm._e(), _c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": _vm.groupPlanTopLink
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('group')) + " ")]), _c('div', {
    staticClass: "topbar-dropdown"
  }, _vm._l(_vm.groupPlans, function (group) {
    return _c('router-link', {
      key: group._id,
      staticClass: "topbar-dropdown-item dropdown-item",
      attrs: {
        "to": {
          name: 'groupPlanDetailTaskInformation',
          params: {
            groupId: group._id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(group.name) + " ")]);
  }), 1)], 1), _c('li', {
    staticClass: "topbar-item droppable",
    class: {
      'active': _vm.$route.path.startsWith('/challenges')
    }
  }, [_c('div', {
    staticClass: "chevron rotate",
    on: {
      "click": function click($event) {
        return _vm.dropdownMobile($event);
      }
    }
  }, [_vm._m(4)]), _c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": {
        name: 'myChallenges'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('challenges')) + " ")]), _c('div', {
    staticClass: "topbar-dropdown"
  }, [_c('router-link', {
    staticClass: "topbar-dropdown-item dropdown-item",
    attrs: {
      "to": {
        name: 'myChallenges'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('myChallenges')) + " ")]), _c('router-link', {
    staticClass: "topbar-dropdown-item dropdown-item",
    attrs: {
      "to": {
        name: 'findChallenges'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('findChallenges')) + " ")])], 1)], 1), _c('li', {
    staticClass: "topbar-item droppable",
    class: {
      'active': _vm.$route.path.startsWith('/help')
    }
  }, [_c('div', {
    staticClass: "chevron rotate",
    on: {
      "click": function click($event) {
        return _vm.dropdownMobile($event);
      }
    }
  }, [_vm._m(5)]), _c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": {
        name: 'faq'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('help')) + " ")]), _c('div', {
    staticClass: "topbar-dropdown"
  }, [_vm.user.permissions.fullAccess || _vm.user.permissions.userSupport || _vm.user.permissions.newsPoster ? _c('router-link', {
    staticClass: "topbar-dropdown-item dropdown-item",
    attrs: {
      "to": {
        name: 'adminPanel'
      }
    }
  }, [_vm._v(" Admin Panel ")]) : _vm._e(), _c('router-link', {
    staticClass: "topbar-dropdown-item dropdown-item",
    attrs: {
      "to": {
        name: 'faq'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('faq')) + " ")]), _c('router-link', {
    staticClass: "topbar-dropdown-item dropdown-item",
    attrs: {
      "to": {
        name: 'overview'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('overview')) + " ")]), _c('a', {
    staticClass: "topbar-dropdown-item dropdown-item",
    attrs: {
      "target": "_blank"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.openBugReportModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reportBug')) + " ")]), _c('a', {
    staticClass: "topbar-dropdown-item dropdown-item",
    attrs: {
      "target": "_blank"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.openBugReportModal(true);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('askQuestion')) + " ")]), _c('a', {
    staticClass: "topbar-dropdown-item dropdown-item",
    attrs: {
      "href": "https://docs.google.com/forms/d/e/1FAIpQLScPhrwq_7P1C6PTrI3lbvTsvqGyTNnGzp1ugi1Ml0PFee_p5g/viewform?usp=sf_link",
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t('requestFeature')))])], 1)], 1)], 1), _c('div', {
    staticClass: "currency-tray form-inline"
  }, [_vm.userHourglasses > 0 ? _c('div', {
    staticClass: "item-with-icon"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      value: _vm.$t('mysticHourglassesTooltip'),
      expression: "$t('mysticHourglassesTooltip')",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "top-menu-icon svg-icon mr-1",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.hourglasses)
    }
  }), _c('span', [_vm._v(_vm._s(_vm.userHourglasses))])]) : _vm._e(), _c('div', {
    staticClass: "item-with-icon gem",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.showBuyGemsModal();
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      value: _vm.$t('gems'),
      expression: "$t('gems')",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "top-menu-icon svg-icon gem mr-2",
    attrs: {
      "aria-label": _vm.$t('gems'),
      "href": "#buy-gems"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.icons.gem)
    }
  }), _c('span', [_vm._v(_vm._s(_vm.userGems))])]), _c('div', {
    staticClass: "item-with-icon gold"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      value: _vm.$t('gold'),
      expression: "$t('gold')",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "top-menu-icon svg-icon mr-2",
    attrs: {
      "aria-label": _vm.$t('gold')
    },
    domProps: {
      "innerHTML": _vm._s(_vm.icons.gold)
    }
  }), _c('span', [_vm._v(_vm._s(Math.floor(_vm.user.stats.gp * 100) / 100))])])]), _c('div', {
    staticClass: "form-inline desktop-only"
  }, [_c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      value: _vm.$t('sync'),
      expression: "$t('sync')",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "item-with-icon",
    attrs: {
      "role": "link",
      "aria-label": _vm.$t('sync'),
      "tabindex": "0"
    },
    on: {
      "click": _vm.sync,
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.sync.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "top-menu-icon svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.sync)
    }
  })]), _c('notification-menu', {
    staticClass: "item-with-icon"
  }), _c('user-dropdown', {
    staticClass: "item-with-icon"
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chevron-icon-down",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.chevronDown)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chevron-icon-down",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.chevronDown)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chevron-icon-down",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.chevronDown)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chevron-icon-down",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.chevronDown)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chevron-icon-down",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.chevronDown)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chevron-icon-down",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.chevronDown)
    }
  });

}]

export { render, staticRenderFns }