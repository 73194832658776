var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "payments-success-modal",
      "hide-footer": _vm.isNewGroup || _vm.isGems || _vm.isSubscription || _vm.ownsJubilantGryphatrice,
      "modal-class": _vm.isNewGroup || _vm.isGems || _vm.isSubscription || _vm.ownsJubilantGryphatrice ? ['modal-hidden-footer'] : []
    }
  }, [_c('div', {
    attrs: {
      "slot": "modal-header"
    },
    slot: "modal-header"
  }, [_c('div', {
    staticClass: "modal-close",
    on: {
      "click": function ($event) {
        return _vm.close();
      }
    }
  }, [_c('div', {
    staticClass: "icon-close",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.close)
    }
  })]), _c('div', {
    staticClass: "check-container d-flex align-items-center justify-content-center"
  }, [_vm._m(0)]), _c('h2', [_vm._v(_vm._s(_vm.$t(_vm.isGemsBalance ? 'success' : 'paymentSuccessful')))])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 modal-body-col"
  }, [_vm.isGems ? [_vm._m(1), _c('div', {
    staticClass: "details-block gems"
  }, [_vm._m(2), _c('span', [_vm._v(_vm._s(_vm.paymentData.gemsBlock.gems))])])] : _vm._e(), _vm.isGiftGems || _vm.isGemsBalance ? [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('paymentYouSentGems', {
        name: _vm.paymentData.giftReceiver
      }))
    }
  }), _c('div', {
    staticClass: "details-block gems"
  }, [_vm._m(3), _c('span', [_vm._v(_vm._s(_vm.paymentData.gift.gems.amount))])])] : _vm._e(), _vm.paymentData.paymentType === 'gift-subscription' ? [_c('div', [_vm.paymentData.g1g1 ? _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('paymentYouSentSubscriptionG1G1', {
        name: _vm.paymentData.giftReceiver,
        months: _vm.paymentData.subscription.months
      }))
    }
  }) : _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('paymentYouSentSubscription', {
        name: _vm.paymentData.giftReceiver,
        months: _vm.paymentData.subscription.months
      }))
    }
  })])] : _vm._e(), _vm.isSubscription ? [_vm._m(4), _c('div', {
    staticClass: "details-block"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('paymentSubBilling', {
        amount: _vm.paymentData.subscription.price,
        months: _vm.paymentData.subscription.months
      }))
    }
  })])] : _vm._e(), _vm.isGroupPlan ? [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t(_vm.isNewGroup ? 'groupPlanCreated' : 'groupPlanUpgraded', {
        groupName: _vm.paymentData.group.name
      }))
    }
  }), _vm.isGroupPlan ? _c('div', {}, [_c('div', {
    staticClass: "details-block group-billing-date"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('groupsPaymentSubBilling', {
        renewalDate: _vm.renewalDate
      }))
    }
  })]), _c('div', {
    staticClass: "small-text group-auto-renew"
  }, [_vm._m(5)])]) : _vm._e()] : _vm._e(), _vm.isSubscription ? [_vm._m(6)] : _vm._e(), _vm.ownsJubilantGryphatrice ? [_c('div', {
    staticClass: "words"
  }, [_c('p', {
    staticClass: "jub-success"
  }, [_vm._m(7)]), _c('p', {
    staticClass: "jub-success"
  }, [_vm._m(8)])]), _c('div', {
    staticClass: "gryph-bg"
  }, [_c('img', {
    attrs: {
      "src": "https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphatrice-Jubilant-Large.gif",
      "alt": "a pink, purple, and green gryphatrice pet winks at you adorably",
      "width": "78px",
      "height": "72px"
    }
  })])] : _vm._e(), _vm.isNewGroup || _vm.isGems || _vm.isSubscription ? _vm._m(9) : _vm._e(), _vm.ownsJubilantGryphatrice ? _c('button', {
    staticClass: "btn btn-primary mx-auto btn-jub",
    on: {
      "click": function ($event) {
        return _vm.closeAndRedirect();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('takeMeToStable')) + " ")]) : _vm._e()], 2)]), _c('div', {
    attrs: {
      "slot": "modal-footer"
    },
    slot: "modal-footer"
  }, [_vm.isGemsBalance || _vm.isGiftGems || _vm.isGiftSubscription ? _c('div', {
    staticClass: "message mx-auto"
  }, [_c('lockable-label', {
    staticClass: "mx-auto label-text",
    attrs: {
      "text": _vm.$t('sendGiftLabel')
    }
  }), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.gift.message,
      expression: "gift.message"
    }],
    staticClass: "form-control mx-auto",
    attrs: {
      "placeholder": _vm.$t('sendGiftMessagePlaceholder')
    },
    domProps: {
      "value": _vm.gift.message
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.gift, "message", $event.target.value);
      }
    }
  }), _c('button', {
    staticClass: "btn btn-primary mx-auto",
    attrs: {
      "disabled": !_vm.gift.message || _vm.sendingInProgress
    },
    on: {
      "click": function ($event) {
        return _vm.sendMessage();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('sendMessage')) + " ")])], 1) : _vm.isUpgradedGroup ? _c('div', {
    staticClass: "demographics d-flex flex-column justify-content-center"
  }, [_c('lockable-label', {
    staticClass: "mx-auto label-text",
    attrs: {
      "text": _vm.$t('groupUse')
    }
  }), _c('select-translated-array', {
    staticClass: "group-input",
    attrs: {
      "items": ['groupParentChildren', 'groupCouple', 'groupFriends', 'groupCoworkers', 'groupManager', 'groupTeacher'],
      "placeholder": 'groupUseDefault',
      "value": _vm.upgradedGroup.demographics
    },
    on: {
      "select": function ($event) {
        _vm.upgradedGroup.demographics = $event;
      }
    }
  }), !_vm.paymentData.newGroup ? _c('button', {
    staticClass: "btn btn-primary mx-auto",
    attrs: {
      "disabled": !_vm.upgradedGroup.demographics
    },
    on: {
      "click": function ($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('submit')) + " ")]) : _vm._e()], 1) : _vm._e()])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon svg-check",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.check)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('strong', [_vm._v(_vm._s(_vm.$t('paymentYouReceived')))]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.gem)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.gem)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('strong', [_vm._v(_vm._s(_vm.$t('nowSubscribed')))]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm._v(_vm._s(_vm.$t('groupsPaymentAutoRenew')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "small-text auto-renew"
  }, [_vm._v(_vm._s(_vm.$t('paymentAutoRenew')))]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('jubilantSuccess'))
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('stableVisit'))
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('onwards')) + " ")]);

}]

export { render, staticRenderFns }